import PartsTable from '$components/PartsTable'
import ServiceStatusButtons from './ServiceStatusButtons'
import ExpandedRow from './ExpandedRow'

const COLUMNS = ['feasible', 'part_id', 'name', 'price_am', 'services']

const MarketplacePartsTable = ({
  selectedParts,
  setSelectedParts,
  setGroup,
}) => {
  return (
    <div className="contentcard">
      <PartsTable
        columns={COLUMNS}
        columnDefinitions={{
          services: {
            title: 'Services',
            customHeader: () => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Services
              </div>
            ),
            className: 'serv-col',
            width: '265px',
            render: (record) => (
              <ServiceStatusButtons variant={record.status_attributes} />
            ),
          },
        }}
        selectable={{
          selectedParts,
          setSelectedParts,
        }}
        expandable={{
          expandedRowRender: (record) => <ExpandedRow record={record} />,
          expandRowByClick: true,
        }}
        filterable={true}
        groupable={{
          setGroup,
        }}
        comparable={true}
      />
    </div>
  )
}

export default MarketplacePartsTable
