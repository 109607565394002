import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Popconfirm } from 'antd'
import { UilTrashAlt } from '@iconscout/react-unicons'

import { useDeleteParts } from '$api/client'
import PartsTable from '$components/PartsTable'
import ExpandedRow from './PartsTable/ExpandedRow'

const AllPartsTable = ({ columns, setPartsTotal }) => {
  const { t } = useTranslation()
  const [selectedParts, setSelectedParts] = useState({})

  const { mutate: deletePartsMutation } = useDeleteParts()

  const allPartsTableFooter = (selectedParts) => (
    <Popconfirm
      title={t('parts_master_data.confirm_parts_deletion', {
        count: Object.keys(selectedParts).length,
      })}
      description={t('parts_master_data.confirm_info_delete_part')}
      placement="topLeft"
      okText={t('button.confirm')}
      cancelText={t('button.cancel')}
      onConfirm={() => {
        deletePartsMutation(selectedParts, {
          onSuccess: () => {
            setSelectedParts({})
          },
        })
      }}
    >
      <Button type="primary" disabled={Object.keys(selectedParts).length === 0}>
        {t('parts_master_data.delete_parts', {
          count: Object.keys(selectedParts).length,
        })}
        <UilTrashAlt
          size="18"
          style={{ margin: '0px 0px -4px 12px', cursor: 'pointer' }}
        />
      </Button>
    </Popconfirm>
  )

  return (
    <PartsTable
      columns={columns}
      expandable={{
        expandedRowRender: (record) => <ExpandedRow record={record} />,
        expandRowByClick: true,
      }}
      selectable={{
        show: true,
        selectedParts,
        setSelectedParts,
      }}
      filterable={true}
      comparable={true}
      onLoad={(_parts, total) => setPartsTotal(total)}
      footer={allPartsTableFooter}
    />
  )
}

export default AllPartsTable
