import { Table } from 'antd'
import { CurrencyWithoutUnit, DateTime } from '$utils/i18n'
import PartNo from './PartNo'
import TextHighlighter from '$components/TextHighlighter'
import TruncateText from '$components/TruncateText'
import { PinIcon } from '$components/Icons'

export const getDefaultColumnDefinitions = ({ t, filters }) => ({
  feasible: {
    title: 'Feasible',
    className: 'feas-col',
    width: '7%',
    render: (part) => {
      const fCValue = part?.attributes?.find(
        ({ key }) => key === 'Feasibility Check',
      )?.value
      const fill =
        fCValue === 'true'
          ? '#34C38F'
          : fCValue === 'false'
            ? '#CD0204'
            : '#F1B44C'
      return (
        <div
          className="traffic-light"
          style={{ width: '12px', margin: '2px 8px -1px 0' }}
        >
          <svg viewBox="0 0 12 12">
            <circle cx="60%" cy="60%" r="4" fill={fill} />
          </svg>
        </div>
      )
    },
  },
  part_id: {
    title: t('Part No'),
    dataIndex: 'id_part_client',
    sorter: true,
    width: '160px',
    render: (_id, part) => (
      <PartNo part={part} filters={filters.id_part_client} />
    ),
    filter: {
      parameter: 'id_part_client',
      operators: ['contains'],
    },
  },
  name: {
    title: t('Part Name'),
    dataIndex: 'name',
    sorter: true,
    render: (name) => (
      <TruncateText
        text={<TextHighlighter text={name} filters={filters.name} />}
      />
    ),
    filter: {
      parameter: 'name',
      operators: ['contains'],
    },
    ellipsis: true,
  },
  width: {
    title: 'Width',
    unit: 'mm',
    sorter: true,
    dataIndex: 'Width',
    width: '6%',
    render: (_, part) =>
      part.float_attributes.find(({ key }) => key === 'Width')?.value,
    filter: {
      parameter: 'Width',
      operators: ['=', '>=', '>', '<=', '<'],
      parse: (val) => parseFloat(val),
    },
  },
  depth: {
    title: 'Depth',
    unit: 'mm',
    sorter: true,
    dataIndex: 'Depth',
    width: '6%',
    render: (_, part) =>
      part.float_attributes.find(({ key }) => key === 'Depth')?.value,
    filter: {
      parameter: 'Depth',
      operators: ['=', '>=', '>', '<=', '<'],
      parse: (val) => parseFloat(val),
    },
  },
  height: {
    title: 'Height',
    unit: 'mm',
    sorter: true,
    dataIndex: 'Height',
    width: '6%',
    render: (_, part) =>
      part.float_attributes.find(({ key }) => key === 'Height')?.value,
    filter: {
      parameter: 'Height',
      operators: ['=', '>=', '>', '<=', '<'],
      parse: (val) => parseFloat(val),
    },
  },
  dimensions: {
    title: 'Dimensions',
    unit: 'mm',
    width: '6%',
    render: (part) => (
      <div>
        {part.float_attributes.find(({ key }) => key === 'Width')?.value}{' '}
        &times;{' '}
        {part.float_attributes.find(({ key }) => key === 'Height')?.value}{' '}
        &times;{' '}
        {part.float_attributes.find(({ key }) => key === 'Depth')?.value}
      </div>
    ),
  },
  weight: {
    title: 'Weight',
    unit: 'g',
    sorter: true,
    dataIndex: 'Weight',
    width: '6%',
    render: (_, part) =>
      part.float_attributes.find(({ key }) => key === 'Weight')?.value,
    filter: {
      parameter: 'Weight',
      operators: ['=', '>=', '>', '<=', '<'],
      parse: (val) => parseFloat(val),
    },
  },
  material: {
    title: 'Material',
    dataIndex: 'material',
    sorter: true,
    render: (_, part) => (
      <TruncateText
        text={part.attributes.find(({ key }) => key === 'Material')?.value}
      />
    ),
    filter: {
      parameter: 'Material',
      operator: '=',
    },
    ellipsis: true,
  },
  price: {
    title: 'Price',
    unit: '€',
    sorter: true,
    dataIndex: 'Price',
    width: '8%',
    render: (_, part) => (
      <CurrencyWithoutUnit
        value={part.float_attributes.find(({ key }) => key === 'Price')?.value}
      />
    ),
  },
  price_am: {
    title: 'Price AM',
    unit: '€',
    sorter: true,
    dataIndex: 'Price AM',
    width: '8%',
    render: (_, part) => (
      <CurrencyWithoutUnit
        value={
          part.float_attributes.find(({ key }) => key === 'Price AM')?.value
        }
      />
    ),
  },
  created_at: {
    title: 'Creation Date',
    dataIndex: 'created_at',
    sorter: true,
    width: '10%',
    render: (created_at) => <DateTime value={created_at} withoutTime={true} />,
  },
  updated_at: {
    title: 'Modification Date',
    dataIndex: 'updated_at',
    sorter: true,
    width: '10%',
    render: (updated_at) => <DateTime value={updated_at} withoutTime={true} />,
  },
})

export const getColumnsArray = (
  t,
  requestedColumns,
  columnDefinitions,
  sorting = {},
  selectable = false,
  setReferencePartId = undefined,
  referencePartId = null,
) => {
  let definitions = []

  if (setReferencePartId && typeof setReferencePartId === 'function') {
    definitions.push({
      title: '',
      dataIndex: 'vector8',
      width: '32px',
      align: 'center',
      sorter: sorting.key === 'vector8',
      sortOrder: sorting.key === 'vector8' ? 'ascend' : null,
      sortDirections: ['ascend'],
      className: 'vector8',
      render: (_, part) => {
        const isPinned = part.isPinned || part.id === referencePartId
        return (
          <PinIcon
            isPinned={isPinned}
            disabled={!part.vector8}
            onClick={(pin) => {
              if (pin) {
                setReferencePartId(part.id)
              } else {
                setReferencePartId(null)
              }
            }}
          />
        )
      },
    })
  }

  if (selectable) {
    definitions.push(Table.SELECTION_COLUMN)
  }

  for (let requestedColumn of requestedColumns) {
    if (typeof requestedColumn === 'string') {
      if (requestedColumn in columnDefinitions) {
        const { unit, title, customHeader, ...definition } =
          columnDefinitions[requestedColumn]
        if (customHeader) {
          definition.title = customHeader(t)
        } else {
          definition.title = unit ? `${t(title)} (${unit})` : t(title)
        }
        if (sorting.key === definition.dataIndex) {
          definition.sortOrder = sorting.order === 1 ? 'ascend' : 'descend'
        } else {
          definition.sortOrder = null
        }
        definitions.push(definition)
      } else {
        console.error(`Unknown column: ${requestedColumn}`)
      }
    }
  }

  return definitions
}
