import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  UilCheckCircle,
  UilTimesCircle,
  UilExclamationCircle,
} from '@iconscout/react-unicons'
import { PushpinOutlined, PushpinFilled } from '@ant-design/icons'
import { theme, Button } from 'antd'
import styled from 'styled-components'
import Tooltip from '$components/Tooltip'

const { useToken } = theme

const Unicon = ({ iconName, colorToken, ...rest }) => {
  const { token } = useToken()

  const props = {
    size: 17,
    style: {
      margin: '0px 8px -4px 0',
    },
    color: token?.[colorToken],
    ...rest,
  }

  switch (iconName) {
    case 'check':
      return <UilCheckCircle {...props} />
    case 'exclamation':
      return <UilExclamationCircle {...props} />
    case 'times':
      return <UilTimesCircle {...props} />
    default:
      return null
  }
}

export const CheckCircle = (props) => (
  <Unicon iconName={'check'} colorToken={'colorSuccess'} {...props} />
)

export const TimesCircle = (props) => (
  <Unicon iconName={'times'} colorToken={'colorError'} {...props} />
)

export const ExclamationCircle = (props) => (
  <Unicon iconName={'exclamation'} colorToken={'colorWarning'} {...props} />
)

const StyledButton = styled(Button)`
  padding: inherit;
`

export const PinIcon = ({
  onClick,
  isPinned = false,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation()
  const [active, setActive] = useState(false)

  let tooltipText = 'parts_table.reference_part.tooltip.instructions'
  if (isPinned) {
    tooltipText = 'parts_table.reference_part.tooltip.cancel'
  } else if (disabled) {
    tooltipText = 'parts_table.reference_part.tooltip.unavailable'
  }

  return (
    <Tooltip title={t(tooltipText)}>
      <StyledButton
        type="link"
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        onClick={(e) => {
          onClick(!isPinned)
          e.stopPropagation()
        }}
        className={isPinned ? 'pinned' : ''}
        disabled={disabled}
      >
        {isPinned || active ? (
          <PushpinFilled {...props} />
        ) : (
          <PushpinOutlined {...props} />
        )}
      </StyledButton>
    </Tooltip>
  )
}
